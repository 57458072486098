<template>
  <section>
    <div class="bg-white courses_section d-none d-md-block">
      <h6
        style="text-align: left; font-weight: 600; color: var(--el-app-primary)"
      >
        My Modules
      </h6>

      <div v-if="!myCourseUnits.length" class="row m-0 w-100">
        <div class="col-12 p-0">
          <el-empty description="No Modules Chosen" :image-size="60"></el-empty>
        </div>
      </div>

      <div class="d-none d-md-flex justify-content-center">
        <carousel
          ref="carouselRef"
          :autoWidth="true"
          :key="carouselKey"
          :dots="true"
          :navText="[
            `<i class='el-icon-arrow-left'></i>`,
            `<i class='el-icon-arrow-right'></i>`,
          ]"
          style="width: 100%; display: flex; align-items: center"
        >
          <div
            class="course2"
            v-for="courseUnit in myCourseUnits"
            :key="courseUnit.course_unit.course_unit_id"
            @click="goToCourseUnit(courseUnit.course_unit.course_unit_id)"
          >
            <div>
              <i
                style="color: white; font-size: 20px"
                class="fas fa-folder-minus"
              ></i
              ><br />
              <small style="color: #f6f6f6"
                ><strong>
                  {{
                    courseUnit.course_unit.title.substring(0, 38) +
                    (courseUnit.course_unit.title.length > 38 ? "..." : "")
                  }}
                </strong></small
              ><br />
              <small style="color: #f6f6f6"
                >({{ courseUnit.course_unit.course_unit_code }})</small
              >
              <br />
            </div>
          </div>
        </carousel>
      </div>
    </div>
  </section>
</template>

<script>
// @ts-ignore
import carousel from "vue-owl-carousel";
import getMonthName from "../helpers/getMonthName";

export default {
  components: { carousel },

  data() {
    return {};
  },

  computed: {
    myCourseUnits() {
      return this.courseUnits.slice(0, 3);
    },
  },
  props: {
    courseUnits: {
      type: Array,
      required: true,
    },
    carouselKey: {
      type: Number,
      required: true,
    },
  },

  methods: {
    toMonthName(monthNum) {
      return getMonthName(monthNum, true);
    },

    format(percentage) {
      return percentage === 100 ? "Full" : `${percentage}%`;
    },

    goToCourseUnit(cuId) {
      this.$router.push(`/dashboard/my-course-units/${cuId}`);
    },
  },
};
</script>

<style scoped>
.bg-white {
  background-color: white;
}

.courses_section {
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 20px;
}

.course2 {
  background-color: var(--vu-red);
  height: 150px;
  width: 200px;
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
  cursor: pointer;
  text-align: left;
}

.coursePercentage {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.coursePercentage > div {
  text-align: right;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .courses_section {
    border-radius: 3px;
    padding: 20px 10px;
    margin-bottom: 20px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .courses_section {
    border-radius: 3px;
    padding: 20px 10px;
    margin-bottom: 20px;
  }
}
</style>

<style>
.owl-prev {
  position: absolute;
  left: -21px;
  top: 39%;
  padding: 6px 11px !important;
  border-radius: 30px !important;
}
.owl-next {
  position: absolute;
  right: -21px;
  top: 39%;
  padding: 6px 11px !important;
  border-radius: 30px !important;
}
.owl-nav.disabled {
  display: block !important;
}
.owl-theme .owl-nav [class*="owl-"] {
  color: #fff;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: rgba(0, 0, 0, 0.2);
  display: none;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme {
  /* background:rgba(0, 0, 0, 0.9); */
  display: flex;
  align-items: center;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .owl-theme .owl-nav [class*="owl-"] {
    color: #fff;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: rgba(0, 0, 0, 0.2);
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
  }
  .owl-theme {
    /* background:rgba(0, 0, 0, 0.9); */
    display: flex;
    align-items: center;
  }

  .owl-theme:hover .owl-nav [class*="owl-"] {
    color: #fff;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #4b88dd;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .owl-theme .owl-nav [class*="owl-"] {
    color: #fff;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: rgba(0, 0, 0, 0.2);
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
  }
  .owl-theme {
    /* background:rgba(0, 0, 0, 0.9); */
    display: flex;
    align-items: center;
  }

  .owl-theme:hover .owl-nav [class*="owl-"] {
    color: #fff;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #4b88dd;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
  }
}
</style>
