import { render, staticRenderFns } from "./security-logs.component.vue?vue&type=template&id=2abfa436&scoped=true"
import script from "./security-logs.component.vue?vue&type=script&lang=ts&setup=true"
export * from "./security-logs.component.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2abfa436",
  null
  
)

export default component.exports