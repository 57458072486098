import Vue from "vue";
import Vuex from "vuex";
import { elearningStore } from "./elearningStore";
import {
  INITIALIZE_STORE,
  LOGIN_USER,
  LOGOUT_USER,
  REFRESH_ACCESS_TOKEN,
  UPDATE_NOTIFICATIONS,
  DO_FORCED_UPDATE,
  UPDATE_EXAM_TIME_LEFT,
  SET_REDIRECT_URL,
  SET_IS_AUTHENTICATING,
} from "./mutation-types";
import jwt_decode from "jwt-decode";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userId: "",
    userInitials: "",
    profilePicture: "",
    accessToken: null as string | null,
    deviceToken: "",
    universityName: "VICTORIA UNIVERSITY",
    logoImageUrl: require("../assets/img/vu-logo-with-words.png"),
    backgroundImage: require("../assets/img/login-img.jpg"),
    secureImg: require("../assets/img/secure.png"),
    notificationsLastChecked: "",
    forcedUpdateLastChecked: "",
    fullName: "",
    regNo: "",
    examTimeLeft: "",
    isPesamoniUser: false,
    isElec: false,
    redirectUrl: null as string | null,
    isAuthenticating: true,
    isAlreadyShowingCheckForUpdatesDialog: false,
  },
  mutations: {
    [UPDATE_NOTIFICATIONS]: (state) => {
      //@ts-ignore
      state.notificationsLastChecked = +new Date();
    },
    [DO_FORCED_UPDATE]: (state) => {
      //@ts-ignore
      state.forcedUpdateLastChecked = +new Date();
    },
    [REFRESH_ACCESS_TOKEN]: (state, { accessToken }) => {
      try {
        //@ts-ignore
        var decoded: String = jwt_decode(accessToken);
        //@ts-ignore
        state.userId = decoded.user_id;
        //@ts-ignore
        state.profilePicture = decoded.pp;
        //@ts-ignore
        state.userInitials = decoded.ui;
        state.accessToken = accessToken;
        //@ts-ignore
        state.isPesamoniUser = decoded.isP;
        //@ts-ignore
        state.isElec = decoded.elec;

        //@ts-ignore
        state.fullName = decoded.fn;

        //@ts-ignore
        state.regNo = decoded.reg_no;
      } catch (error) {
        //Nothing to do
      }
    },
    [LOGIN_USER]: (state, { accessToken, fbDeviceToken }) => {
      //Save The Access Token in local Storage
      state.deviceToken = fbDeviceToken;
      try {
        //@ts-ignore
        var decoded: String = jwt_decode(accessToken);
        //@ts-ignore
        state.userId = decoded.user_id;
        //@ts-ignore
        state.profilePicture = decoded.pp;
        //@ts-ignore
        state.userInitials = decoded.ui;
        state.accessToken = accessToken;
        //@ts-ignore
        state.isPesamoniUser = decoded.isP;
        //@ts-ignore
        state.isElec = decoded.elec;

        //@ts-ignore
        state.fullName = decoded.fn;

        //@ts-ignore
        state.regNo = decoded.reg_no;
      } catch (error) {
        //Nothing to do
      }

      localStorage.setItem("s-dt", fbDeviceToken);
    },
    [LOGOUT_USER]: (state) => {
      state.userId = "";
      state.accessToken = "";
      state.userInitials = "";
      state.profilePicture = "";
      state.deviceToken = "";
      localStorage.removeItem("s-dt");
    },
    [INITIALIZE_STORE]: (state) => {
      state.deviceToken = localStorage.getItem("s-dt") || "";
    },
    [SET_REDIRECT_URL]: (state, payload) => {
      state.redirectUrl = payload;
    },
    [SET_IS_AUTHENTICATING]: (state, payload) => {
      state.isAuthenticating = payload;
    },

    [UPDATE_EXAM_TIME_LEFT]: (state, payload) => {
      state.examTimeLeft = payload;
    },
  },
  getters: {
    isLoggedIn: (state) => {
      return !!state.userId;
    },
    // deviceToken: state => state.accessToken
  },
  plugins: [elearningStore],
  actions: {},
  modules: {},
});
