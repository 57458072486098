<template>
  <!--admin@vu.ac.ug--0754187856---->
  <section>
    <div class="container">
      <br />
      <div v-if="!loading && !loadingError">
        <CourseSummaryBanner :course-info="courseInfo" />
      </div>

      <section v-if="!loading && !loadingError" style="text-align: left">
        <div class="mt-3" style="display: flex; justify-content: flex-end">
          <div>
            <el-button
              @click="chooseCourseUnits"
              style="background-color: var(--vu-red); color: white"
              >Choose your Modules</el-button
            >

            <el-button
              @click="applyForResitsAndRetakes"
              type="primary"
              class="ml-2"
            >
              Apply for Resits & Retakes
            </el-button>

            <el-dropdown @command="handleCommand">
              <el-button type="primary" class="ml-2">
                More Actions
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="Modules History"
                  >Modules History</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div>
          <div
            v-if="
              courseInfo.retake_course_units &&
              courseInfo.retake_course_units.length
            "
            style="line-height: 18px; margin-top: 30px"
          >
            <span
              style="
                font-size: 18px;
                font-weight: 400;
                color: #164b70;
                padding-left: 10px;
              "
            >
              <strong>Retakes </strong>
            </span>
            <div>
              <div
                class="course-units-row"
                style="padding-left: 10px; padding-top: 10px"
              >
                <div
                  v-for="(courseUnit, i) in courseInfo.retake_course_units"
                  :key="i"
                >
                  <div>
                    <CourseUnitCardItem
                      :course-unit="courseUnit"
                      :course-name="courseInfo.course.course_name"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="semester_section"
            v-for="(year, key) in formattedModules"
            :key="key"
          >
            <div style="line-height: 18px">
              <span style="font-size: 18px; font-weight: 400; color: #164b70">
                <strong>Year {{ key }} </strong>
              </span>
            </div>
            <div
              v-for="(trimester, key) in year"
              :key="key"
              style="padding-top: 30px"
            >
              <div>
                <p style="font-weight: 500; color: var(--el-app-primary)">
                  <span>Trimester {{ key }}</span>
                </p>
              </div>

              <div class="course-units-row">
                <div
                  v-for="courseUnit in trimester"
                  :key="courseUnit.course_unit_id"
                >
                  <CourseUnitCardItem
                    :course-unit="courseUnit"
                    :course-name="courseInfo.course.course_name"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div v-if="loading" class="col-12">
        <ScaleOut style="margin-top: 150px" :background="'#1F4EA1'" />
        <div class="mt-3" style="font-weight: 500; font-size: 0.9em">
          Fetching Student Content
        </div>
        <div style="font-weight: 500; font-size: 0.9em">Please Wait...</div>
      </div>

      <div
        v-if="!loading && loadingError"
        class="col-12"
        style="margin-top: 100px"
      >
        <span style="font-weight: 600">Unable to get Programme Info</span>
        <div class="pt-2" style="font-size: 0.9em">
          Failed to get Programme Information right now. Click below to retry
        </div>
        <div class="mt-1 col-12">
          <button
            type="button"
            class="btn z-depth-0"
            @click="getStudentCourseInfo"
            v-ripple="'rgba(255, 255, 255, 0.35)'"
            style="
              width: 250px;
              text-transform: capitalize;
              height: 45px;
              border-radius: 4px;
              background-color: red;
              font-weight: 500;
            "
          >
            <span style="position: relative; bottom: 1px">Try Again</span>
          </button>
        </div>
      </div>
    </div>

    <section>
      <div class="courseUnit_dialog" style="text-align: left">
        <el-dialog
          title="My Modules"
          :visible.sync="courseUnitsModal"
          :destroy-on-close="true"
        >
          <div v-loading="isBusy">
            <div
              style="
                margin: 0px 0px 30px 0px;
                padding: 10px;
                background-color: #f6f6f6;
              "
              class="semester_section"
            >
              <div
                class="semester_section"
                v-for="(year, key) in formattedCourseUnits"
                :key="key"
              >
                <div style="line-height: 18px">
                  <span
                    style="font-size: 18px; font-weight: 400; color: #164b70"
                  >
                    <strong>Year {{ key }} </strong>
                  </span>
                </div>
                <div
                  v-for="(trimester, key) in year"
                  :key="key"
                  style="padding-top: 30px"
                >
                  <div>
                    <p style="font-weight: 500; color: var(--el-app-primary)">
                      <span>Trimester {{ key }}</span>
                    </p>
                  </div>
                  <el-checkbox-group
                    class="mx-0 row w-100"
                    v-model="selectedCourseUnitIds"
                  >
                    <el-checkbox
                      class="mx-0 col-lg-6"
                      v-for="courseUnit in trimester"
                      :label="courseUnit.course_unit_id"
                      :key="courseUnit.course_unit_id"
                      :disabled="courseUnit.is_selected"
                    >
                      {{ courseUnit.title }} (<strong>{{
                        courseUnit.course_unit_code
                      }}</strong
                      >)
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>

            <div style="display: flex; justify-content: flex-end">
              <el-button
                @click="onSaveStudentModules"
                style="background-color: var(--el-app-primary); color: white"
                >Save Changes <i class="el-icon-arrow-right"></i
              ></el-button>
            </div>
          </div>
        </el-dialog>
      </div>
    </section>
  </section>
</template>

<script>
import ScaleOut from "@/components/scale-out-component.vue";
import getMonthName from "../../helpers/getMonthName";
import nestedGroupby from "nested-groupby";
import CourseUnitCardItem from "@/components/course-unit-card-item.vue";
import CourseSummaryBanner from "@/components/course-summary-banner.component.vue";

export default {
  components: { ScaleOut, CourseUnitCardItem, CourseSummaryBanner },
  data() {
    return {
      loading: false,
      isBusy: false,

      loadingError: false,
      courseUnitsModal: false,
      courseInfo: {},

      checkedCourseUnits: [],
      sem_course_units: [],

      compulsory: [],
      electives: [],

      checked: true,

      optionalIds: [],
      selectedOptionalIds: [],
      selectedCourseUnitIds: [],

      retakeCourseUnits: [],
    };
  },

  computed: {
    toMonthName() {
      return (monthInt) => {
        return getMonthName(monthInt, true);
      };
    },

    formattedModules() {
      return nestedGroupby(this.courseInfo?.course_units, [
        "year_of_study",
        "semester_of_study",
      ]);
    },

    formattedCourseUnits() {
      return nestedGroupby(this.sem_course_units, [
        "year_of_study",
        "semester_of_study",
      ]);
    },
  },

  mounted() {
    this.getStudentCourseInfo();
  },

  methods: {
    handleCommand(command) {
      if (command == "Modules History") {
        this.$router.push({ path: `student-modules-history` });
      }
    },

    applyForResitsAndRetakes() {
      this.$router.push({ path: `my-course-units/failed` });
    },

    handleClick(tab, event) {
      console.log(tab, event);
    },

    chooseCourseUnits() {
      this.courseUnitsModal = true;
      this.fetchCourseUnitsForSelection();
    },

    async getStudentCourseInfo() {
      this.loading = true;
      this.loadingError = false;
      try {
        let request = await this.$http.get(
          `students/fetch-course-details/${this.$store.state.userId}`
        );
        if (
          request.data.success &&
          request.data.message == "STUDENT_COURSE_INFO_RETRIEVED_SUCCESSFULLY"
        ) {
          this.courseInfo = request.data.course_info;
        } else if (request.data.message == "BATCH_COHORT_IS_NULL") {
          this.showWarningMessage(
            "No Cohort",
            "Cohort has not been set on your intake. Please reach out to your school adminstration."
          );
          this.courseUnitsModal = false;
        } else {
          this.$rollbar.warning("STUDENTS FRONT END: Unexpected API response", {
            response: request.data,
            request,
          });
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        this.loadingError = true;

        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        if (error.response) {
          if (
            error.response.data.message === "STUDENT'S INTAKE HAS NO CURRICULUM"
          ) {
            return this.showWarningMessage(
              "No Curriculum",
              "Your Cohort does not have a Curriculum assigned to it, please contact your Admin"
            );
          }
        }

        this.showFailedMessage(
          "Programmes Not Loaded",
          "Unable to fetch Programmes Information Now, Please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    async onSaveStudentModules() {
      //Only keep course units that are not locked i.e is_locked == false
      const selectedCourseUnitIds = this.selectedCourseUnitIds.filter(
        (courseUnitId) => {
          return this.sem_course_units.some((courseUnit) => {
            return (
              courseUnit.course_unit_id == courseUnitId && !courseUnit.is_locked
            );
          });
        }
      );
      let request = await this.httpRequest({
        method: "POST",
        url: `students/save-course-units-for-semester/${this.$store.state.userId}`,
        loadingPropertyName: "isBusy",
        showSuccessMessage: true,
        body: {
          selected_course_units: selectedCourseUnitIds,
        },
      });

      if (
        request &&
        request.success &&
        request.message == "Course Units successfully saved"
      ) {
        this.getStudentCourseInfo();
        this.courseUnitsModal = false;
      }
    },

    async fetchCourseUnitsForSelection() {
      this.isBusy = true;
      try {
        let request = await this.$http.get(
          `students/fetch-course-units-for-selection-current-semester/${this.$store.state.userId}`
        );
        if (
          request.data.success &&
          request.data.message == "STUDENT_COURSE_UNITS_FETCHED_SUCCESSFULLY"
        ) {
          this.sem_course_units = request.data.course_units;

          this.selectedCourseUnitIds = this.sem_course_units.flatMap((item) => {
            return item.is_selected ? [item.course_unit_id] : [];
          });
        } else if (request.data.message == "BATCH_COHORT_IS_NULL") {
          this.showWarningMessage(
            "No Cohort",
            "Cohort has not been set on your intake. Please reach out to your school administration."
          );
          this.courseUnitsModal = false;
        } else if (request.data.message == "CURRENT_SEMESTER_IS_MISSING") {
          this.showWarningMessage(
            "No current semester",
            "Current semester not been set. Please reach out to your school administration."
          );
          this.courseUnitsModal = false;
        } else {
          this.$rollbar.warning("STUDENTS FRONT END: Unexpected API response", {
            response: request.data,
            request,
          });
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        } //If the API returns other status codes besides 2xx, Handle these responses
        if (error.response) {
          if (
            error.response.data.message == "STUDENT'S INTAKE HAS NO CURRICULUM"
          ) {
            this.myModulesDialog = false;
            this.$emit("closeModulesDialogDialog");
            return this.showWarningMessage(
              "No Curriculum",
              "Your Cohort does not have a Curriculum assigned to it, please contact your Admin"
            );
          }
        }

        return this.showFailedMessage(
          "Modules failed to load",
          "Unable to fetch Modules Information Now, Please try again"
        );
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>

<style scoped>
.text-grey {
  color: rgb(187, 187, 187);
}

.semester_section {
  margin: 20px 0px;
  padding: 20px 10px;
  border-radius: 5px;
  /* background-color: #e6e8e8; */
}

.courses_section {
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 20px;
}

.course-units-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.course-units-row > div {
  background-color: white;
  border: 1px solid #e7e6e6;
  border-radius: 5px;
  padding: 15px;
}

.course-units-row > div:hover {
  box-shadow: 0 8px 6px -6px rgba(161, 156, 161, 1);
}

.search_by_input {
  width: 400px !important;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  .course-units-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5px;
  }

  .semester_section {
    margin: 20px 0px;
    padding: 20px 10px;
    border-radius: 5px;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .course-units-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5px;
  }

  .semester_section {
    margin: 20px 0px;
    padding: 20px 10px;
    border-radius: 5px;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .course-units-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }

  .courses_section {
    border-radius: 3px;
    padding: 20px 10px;
    margin-bottom: 20px;
  }

  .semester_section {
    margin: 15px 0px;
    padding: 15px 10px;
    border-radius: 5px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .course-units-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }

  .courses_section {
    border-radius: 3px;
    padding: 20px 10px;
    margin-bottom: 20px;
  }

  .semester_section {
    margin: 15px 0px;
    padding: 15px 10px;
    border-radius: 5px;
  }
}
</style>

<style>
.courseUnit_dialog .el-dialog {
  width: 80%;
  text-align: left;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  .courseUnit_dialog .el-dialog {
    width: 70%;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .courseUnit_dialog .el-dialog {
    width: 80%;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .courseUnit_dialog .el-dialog {
    width: 90%;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .courseUnit_dialog .el-dialog {
    width: 90%;
  }
}
</style>
