import store from "@/store";
import { AxiosResponse } from "axios";

export const compareAppVersion = (response: AxiosResponse<any>) => {
  //We do not want to prompt the user to update the app if they are on the payments page as they could end up
  //refreshing the page and losing the payment they were trying to make which would be a bad UX
  const isOnPaymentsPage = window.location.href.indexOf("/payments/") !== -1;

  if (
    !response ||
    isOnPaymentsPage ||
    !process.env.VUE_APP_CHECK_APP_VERSION_FROM_API
  )
    return;

  const appVersion = process.env.VUE_APP_VERSION;
  if (response.data?._v) {
    if (response.data?._v !== appVersion) {
      store.state.isAlreadyShowingCheckForUpdatesDialog = true;
    }
  }
};
