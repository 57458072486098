export const NON_LOGGED_IN_USER_TOKEN =
  "Vc$@SS.@2022.sTuD3nTInqU1Ry.12LMM2FO1CEAVcuqlwM5zTXihPr2dsT9IhiktYvyk6Uim.RjdK2jW9PsWBntgdlZb27lJYsUN0FwQKOEHBBzZtNSG6ZOV9e9Y";
export const APPLICATION_VERSION = 13;
export const CHATS_SSE_BASE_URL =
  "https://api.vclass.ac:9904/api/streams-server/v1.2/victoria-university/";
export const SSE_SERVER_BASE_URL =
  process.env.VUE_APP_SSE_SERVER_BASE_URL || "https://api.vclass.ac:9904";

export const APPLICATION_FEE_PAYMENT_AMOUNT_IN_UGX = 50000;
export const APPLICATION_FEE_PAYMENT_AMOUNT_IN_USD = 16;

export const ADMISSION_FEE_PAYMENT_AMOUNT_IN_UGX = 200000;
export const ADMISSION_FEE_PAYMENT_AMOUNT_IN_USD = 62;

export const EXAMINATION_RESIT_FEE_UGX = 100000;
export const EXAMINATION_RESIT_FEE_USD = 32;

export const COURSE_UNIT_RETAKE_FEE_UGX = 200000;
export const COURSE_UNIT_RETAKE_FEE_USD = 64;
