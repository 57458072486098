<template>
  <section v-loading="dataLoading" class="container">
    <div class="dashboard_inner_grid">
      <div>
        <div style="margin-bottom: 20px">
          <NoticeBoardDisplayComponent
            :notices="notices"
          ></NoticeBoardDisplayComponent>
        </div>

        <!---THE Courses component- -->
        <CoursesComponent
          :courseUnits="courseUnits"
          :carouselKey="carouselKey"
        ></CoursesComponent>

        <div class="row w-100 mx-0">
          <div class="col-md-6 px-0">
            <LiveLectureAttendanceStatsChart />
          </div>
          <div class="col-md-6 card-item" style="cursor: pointer">
            <el-carousel height="350px">
              <el-carousel-item v-for="item in 5" :key="item">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                  @click="index = item - 1"
                >
                  <img
                    :src="
                      require(`../assets/img/elections/elections-img-${item}.jpg`)
                    "
                    style="border-radius: 5px"
                    class="w-100"
                    alt=""
                  />
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>

      <div class="bg-white" style="border-radius: 3px">
        <CalendarComponent
          :eventsDays="eventDays"
          :year="calendarYear"
          :month="calendarMonth"
          :dayToday="dayToday"
        ></CalendarComponent>
        <TodaysScheduleComponent
          :our_upcoming_lectures="upcoming_lectures"
        ></TodaysScheduleComponent>
      </div>
    </div>

    <CoolLightBox
      :items="items"
      :index="index"
      @close="index = null"
      :useZoomBar="true"
      :effect="'fade'"
    >
    </CoolLightBox>
  </section>
</template>

<script>
import CoursesComponent from "../components/courses-component.vue";
import CalendarComponent from "../components/calendar-component.vue";
import TodaysScheduleComponent from "../components/todays-schedule-component.vue";
import NoticeBoardDisplayComponent from "../components/notice-board-display.vue";
import CoolLightBox from "vue-cool-lightbox";
import LiveLectureAttendanceStatsChart from "@/components/live-lecture-attendance-stats-chart.vue";

export default {
  components: {
    CoursesComponent,
    CalendarComponent,
    TodaysScheduleComponent,
    CoolLightBox,
    NoticeBoardDisplayComponent,
    LiveLectureAttendanceStatsChart,
  },

  data() {
    return {
      dataLoading: false,
      eventDays: [],
      calendarMonth: 1,
      calendarYear: 2021,
      dayToday: null,
      upcoming_lectures: [],
      courseUnits: [],
      notices: [],
      faculty_count: "",
      course_count: "",
      student_count: "",
      staff_count: "",
      batch_count: "",
      carouselKey: 1,

      //Light box configs
      items: [
        "https://victoria-university-elearning.s3.amazonaws.com/vu_payment_images/Direct+Bank+payments.jpg",
        "https://victoria-university-elearning.s3.amazonaws.com/vu_payment_images/Flexipay.jpg",
        "https://victoria-university-elearning.s3.amazonaws.com/vu_payment_images/Mobile+and+Online+payments.jpg",
        "https://victoria-university-elearning.s3.amazonaws.com/vu_payment_images/Mobile+payments.jpg",
        "https://victoria-university-elearning.s3.amazonaws.com/vu_payment_images/Fees+Payment+options.jpg",
      ],
      index: null,
    };
  },
  mounted() {
    this.fetchStudentData();
  },
  methods: {
    async fetchStudentData() {
      try {
        this.dataLoading = true;
        let request = await this.$http.get(
          `students/fetch-dashboard-home/${this.$store.state.userId}`
        );
        if (
          request.data.success &&
          request.data.message == "DASHBOARD_CONTENT_FETCHED_SUCCESSFULLY"
        ) {
          const today = request.data.date_today.split("-");
          this.eventDays = request.data.days;
          this.calendarYear = +today[0];
          this.calendarMonth = +today[1];
          this.dayToday = +today[2];
          this.upcoming_lectures = request.data.upcoming_lectures;
          this.courseUnits = request.data.course_units;
          this.notices = request.data.notices;
          ++this.carouselKey;
        } else {
          this.$rollbar.warning("STUDENTS FRONT END: Unexpected API response", {
            response: request.data,
            request,
          });
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }
        this.showFailedMessage(
          "Unable to load student data",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.dataLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.in-the-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.round-ball-lg {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: black;
}

.bg-white {
  background-color: white;
}

.card-item {
  /* background-color: var(--el-app-primary); */
  color: white;
}

.dashboard_inner_grid {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
  margin: 20px 0px;
}

.dashboard_inner_grid > div {
  height: 100%;
}

.grid_row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.grid_row > div {
  height: 100%;
  border-radius: 5px;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(144deg);
    transform: rotate(144deg);
  }
}

@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .dashboard_inner_grid {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 20px;
    margin: 20px 0px;
  }

  .dashboard_inner_grid > div {
    height: 100%;
  }

  .grid_row {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .grid_row > div {
    height: 100%;
    border-radius: 5px;
    padding: 30px 20px;
  }

  .grid_row > div:first-child {
    display: none;
  }
}
</style>

<style>
.el-carousel__arrow {
  background-color: #4b88dd;
}
</style>
